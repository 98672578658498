<template>
  <div
    class="mt-0"
  >
    <v-col>
      <v-card
        elevation="0"
        class="pt-0"
        color="#eee"
        :height="($vuetify.breakpoint.height - 96).toString()"
        style="overflow-y: auto; overflow-x: hidden;"
      >
        <v-toolbar
          dense
          flat
          style="position: sticky; top: 0px; z-index: 1"
        >
          <v-icon
            small
            color="primary"
          >
            mdi-pencil
          </v-icon>
          <div
            class="ml-1"
            :style="`font-size: 17px; font-weight: bold; color: ${$vuetify.theme.themes[theme].primary}`"
          >
            {{ $t('cases|filling_assistant') }}
          </div>
        </v-toolbar>
        <v-form v-if="actualSuggestionsEditorStorage.length">
          <v-container>
            <v-row justify="center">
              <v-col cols="12">
                <div
                  v-for="(node, index) in filteredStorageFields"
                  :key="node.attrs.id"
                  class="mx-auto my-4"
                >
                  <v-card
                    class="py-4 px-5"
                  >
                    <v-text-field
                      v-model="information[index]"
                      :placeholder="node.attrs.fieldValue"
                      :persistent-placeholder="node.attrs.fieldValue ? true : false "
                      dense
                      :hint="$t('expressions|information_can_be_filled_in_here')"
                      persistent-hint
                      class="mb-4"
                      @input="applyData(node, information[index])"
                    >
                      <span
                        slot="label"
                        style="font-weight: bold;"
                      >
                        {{ `@${node.attrs.customLabel ? node.attrs.label + '[' + node.attrs.customLabel + ']' : node.attrs.label}` }}
                      </span>
                    </v-text-field>
                    <v-select
                      v-model="information[index]"
                      :items="extractedValues(node)"
                      item-value="value"
                      item-text="value"
                      :label="$t('documents|extracted_data')"
                      dense
                      :menu-props="{ bottom: true, offsetY: true }"
                      class="mt-2"
                      @input="applyData(node, information[index])"
                    >
                      <span
                        slot="label"
                        style="font-size: 12px;"
                      >
                        {{ $t('documents|extracted_data') }}
                      </span>
                      <template v-slot:item="{ on, item }">
                        <v-list-item
                          two-line
                          v-on="on"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              class="font-weight-bold"
                            >
                              {{ item.value }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <!-- <v-icon
                                x-small
                              >
                                mdi-file
                              </v-icon> -->
                              {{ item.doc.raw.originalname }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  color="primary"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click.stop="openDocument(item.doc)"
                                >
                                  <v-icon>
                                    mdi-file
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t('actions|open') }}</span>
                            </v-tooltip>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                    </v-select>
                    <v-select
                      v-model="information[index]"
                      :items="questionnaireValues(node)"
                      item-value="value"
                      item-text="value"
                      dense
                      :menu-props="{ bottom: true, offsetY: true }"
                      class="mt-2"
                      @input="applyData(node, information[index])"
                    >
                      <span
                        slot="label"
                        style="font-size: 12px;"
                      >
                        {{ $t('questionnaire|client_answer_from_questionnaire') }}
                      </span>
                    </v-select>
                  </v-card>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <div
          v-else
          class="pa-3"
        >
          {{ $t('mixed|no_actual_suggestions') }}
        </div>
      </v-card>
    </v-col>
    <div v-show="false">
      <Editor
        ref="editor"
        :edit="true"
        :language="template.language"
        :questionnaire="template.questionnaire"
        :template-field-tags="templateFieldTags"
        :related-documents="workbench ? workbench.assignedDocuments : []"
        :template-id="template._id"
        :template="template"
        :workbench="workbench"
        :case-data="caseData"
        :options="{
          inCase: true
        }"
        :current-case-form="currentCaseForm"
        :account="account"
        :node-values-to-be-applied="nodeValuesToBeApplied"
      />
    </div>
    <FileView
      ref="fileView"
      :case-data="caseData"
      :in-case="true"
    />
  </div>
</template>

<script>
import Editor from '../TemplateEditor/Editor.vue'
import { mapState } from 'vuex'
import { mdiArrowTopRightThinCircleOutline } from '@mdi/js'
import generalMixin from '@/utils/generalMixin.js'
import debounce from 'lodash/debounce'
import FileView from '../../components/dialogs/FileView.vue'

export default {
  components: {
    Editor,
    FileView
  },
  mixins: [generalMixin],
  props: {
    language: {
      type: String,
      default: 'en'
    },
    questionairre: {
      type: Object,
      default: null
    },
    relatedDocuments: {
      type: Array,
      default: () => ([])
    },
    edit: {
      type: Boolean,
      default: false
    },
    readOnlyOption: {
      type: Boolean,
      default: true
    },
    template: {
      type: Object,
      default: null
    },
    templateId: {
      type: String,
      default: null
    },
    workbench: {
      type: Object,
      default: null
    },
    caseData: {
      type: Object,
      default: null
    },
    currentCaseForm: {
      type: Object,
      default: null
    },
    // templateData: {
    //   type: Object,
    //   default: null
    // },
    // actualTemplateDataJson: {
    //   type: Object,
    //   default: null
    // },
    account: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: () => ({
        hasClose: false,
        inCase: false,
        inCaseModel: false,
        inTemplate: false
      })
    }
  },
  data () {
    return {
      information: [],
      nodeValuesToBeApplied: [],
      nodes: [],
      scrollNodesArr: [],
      icons: {
        open: mdiArrowTopRightThinCircleOutline
      }
    }
  },
  computed: {
    ...mapState({
      settings: state => state.settings.settings,
      actualSuggestionsEditorStorage: state => state.editorStore.actualSuggestionsEditorStorage
    }),
    theme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
    templateFieldTags () {
      const fields = this.actualSuggestionsEditorStorage.map((f) => {
        let key
        if (f.attrs.fieldKey) {
          key = f.attrs.fieldKey.toLowerCase()
        } else {
          key = f.attrs.label.toLowerCase().split(' ').join('_')
        }
        const customHashtag = f.attrs.customHashtag
        const inUse = this.template.questionnaire.fields.findIndex(field => {
          if (field.key && key === field.key.toLowerCase()) {
            return f.attrs.customLabel === field.customLabel
          } else {
            return false
          }
        })
        return {
          customHashtag,
          fieldType: f.attrs.fieldType,
          fieldKey: f.attrs.fieldKey,
          hint: '',
          id: f.attrs.id,
          customLabel: f.attrs.customLabel,
          key: key,
          name: f.attrs.label,
          inUse: inUse !== -1
        }
      })
      const uniqueFields = fields.reduce((acc, field) => {
        const foundField = acc.find(i => i.key === field.key)
        if (foundField) { // already in array
          const foundWithCustomLabel = acc.find(i => i.customLabel === field.customLabel)
          if (!foundWithCustomLabel) acc.push(field)
        } else {
          acc.push(field)
        }
        return acc
      }, [])
      return uniqueFields
    },
    filteredStorageFields () {
      // filter all suggestions from template and return only with the same fieldKey/customLabel
      // in the filling assistent the user is applying the information to all fields
      // apllaying info to single field can be done with right click on the suggestion in template
      const filtered = this.actualSuggestionsEditorStorage.reduce(function (previousValue, currentValue) {
        const found = previousValue.find(item => item.attrs.customLabel === currentValue.attrs.customLabel && item.attrs.fieldKey.toLowerCase() === currentValue.attrs.fieldKey.toLowerCase())
        if (!found) {
          previousValue.push(currentValue)
        }
        return previousValue
      }, [])
      return filtered
    }
  },
  created () {
    this.applyData = debounce(this.applyData, 1000)
  },
  methods: {
    extractedValues (node) {
      const arr = []
      this.relatedDocuments.forEach((doc) => {
        if (doc.info && doc.info.content && Array.isArray(doc.info.content)) {
          doc.info.content.forEach(content => {
            if (content.lavvira_labels) {
              Object.keys(content.lavvira_labels).forEach((key) => {
                if ((node.attrs.fieldKey.toLowerCase() === key.toLowerCase()) || node.attrs.fieldClass?.toLowerCase() === key.toLowerCase()) {
                  content.lavvira_labels[key].forEach((val) => {
                    arr.push({
                      value: val,
                      doc
                    })
                  })
                }
              })
            }
          })
        }
      })
      return arr
    },
    questionnaireValues (node) {
      let valArr = []
      this.template.questionnaire.fields.filter((f) => {
        if (f.value && f.fieldKey.toLowerCase() === node.attrs.fieldKey.toLowerCase()) {
          valArr.push(f)
        }
      })
      return valArr
    },
    applyData (node, data) {
      // direct apply => suitable for applying data only on single fields
      // this.$refs.editor.editor.commands.setNodeSelection(inputNode.pos)
      // this.$refs.editor.editor.commands.updateAttributes('mention', { fieldValue: data })

      // send the nodes array to the editor => watcher is waiting and applying the content
      const foundIndex = this.nodeValuesToBeApplied.findIndex(obj => obj.node.attrs.id === node.attrs.id)
      if (foundIndex === -1) {
        node.attrs.fieldValue = data
        this.nodeValuesToBeApplied.push({ node, data: data })
      } else {
        this.nodeValuesToBeApplied[foundIndex].node.attrs.fieldValue = data
        this.nodeValuesToBeApplied[foundIndex].data = data
      }
    }
  }
}
</script>
